import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextLink } from '@kp/react-ui';
import { createClasses } from '../../utils/css';
import { ErrorLayout } from '../ErrorLayout';

const classes = createClasses({
  links: {
    textAlign: 'center',
    marginTop: '24px',
  },
});

interface ErrorNotFoundProps {}

export const ErrorNotFound: React.FC<ErrorNotFoundProps> = () => {
  const { t } = useTranslation();

  return (
    <ErrorLayout title={t('errors.notFound.title')}>
      <p className={classes.links}>
        <TextLink href="/">{t('errors.notFound.button')}</TextLink>
      </p>
    </ErrorLayout>
  );
};
