declare global {
  interface Window {
    ENV_OPEN_WEATHER_MAP_API_KEY: string;
    ENV_APPLICATIONINSIGHTS_CONNECTION_STRING: string;
  }
}

export const OPEN_WEATHER_MAP_API_KEY = window.ENV_OPEN_WEATHER_MAP_API_KEY;
export const APPLICATIONINSIGHTS_CONNECTION_STRING =
  window.ENV_APPLICATIONINSIGHTS_CONNECTION_STRING;
