import { OPEN_WEATHER_MAP_API_KEY } from '../utils/env';

const { NODE_ENV } = process.env;

export type WeatherDataCurrent = {
  dt: number;
  sunrise: number;
  sunset: number;
  temp: number;
  feels_like: number;
  pressure: number;
  humidity: number;
  dew_point: number;
  uvi: number;
  clouds: number;
  visibility: number;
  wind_speed: number;
  wind_deg: number;
  weather: Array<{
    id: number;
    main: string;
    description: string;
    icon: string;
  }>;
  rain?: {
    '1h': number;
  };
  snow?: {
    '1h': number;
  };
};
export type WeatherDataHourly = {
  dt: number;
  temp: number;
  feels_like: number;
  pressure: number;
  humidity: number;
  dew_point: number;
  uvi: number;
  clouds: number;
  visibility: number;
  wind_speed: number;
  wind_deg: number;
  wind_gust: number;
  weather: Array<{
    id: number;
    main: string;
    description: string;
    icon: string;
  }>;
  pop: number;
  rain?: {
    '1h': number;
  };
  snow?: {
    '1h': number;
  };
};
export type WeatherDataDaily = {
  dt: number;
  sunrise: number;
  sunset: number;
  moonrise: number;
  moonset: number;
  moon_phase: number;
  temp: {
    day: number;
    min: number;
    max: number;
    night: number;
    eve: number;
    morn: number;
  };
  feels_like: {
    day: number;
    night: number;
    eve: number;
    morn: number;
  };
  pressure: number;
  humidity: number;
  dew_point: number;
  wind_speed: number;
  wind_deg: number;
  wind_gust: number;
  weather: Array<{
    id: number;
    main: string;
    description: string;
    icon: string;
  }>;
  clouds: number;
  pop: number;
  rain?: number;
  uvi: number;
};
export type WeatherData = {
  lat: number;
  lon: number;
  timezone: string;
  timezone_offset: number;
  current?: WeatherDataCurrent;
  minutely?: Array<{
    dt: number;
    precipitation: number;
  }>;
  hourly?: Array<WeatherDataHourly>;
  daily?: Array<WeatherDataDaily>;
  alerts?: Array<{
    sender_name: string;
    event: string;
    start: number;
    end: number;
    description: string;
  }>;
};

export type WeatherCoordinates = {
  name: string;
  lat: number;
  lon: number;
  country: string;
};

export const getWeather = async (
  lat: number,
  lon: number,
  lang: string = 'en',
): Promise<WeatherData> => {
  const url = 'https://api.openweathermap.org/data/2.5/onecall';

  const query = new URLSearchParams({
    lat: lat.toString(),
    lon: lon.toString(),
    exclude: 'minutely',
    units: 'metric',
    lang,
    appId: OPEN_WEATHER_MAP_API_KEY,
  });

  if (NODE_ENV === 'development') {
    console.warn(
      'Using real Weather data be careful with the number of API calls.',
    );
  }
  const response = await fetch(`${url}?${query.toString()}`, {
    method: 'GET',
  });

  if (!response.ok) throw new Error(response.statusText);
  return (await response.json()) as WeatherData;
};

export const getCoordinates = async (
  city: string,
): Promise<WeatherCoordinates[]> => {
  const url = 'https://api.openweathermap.org/geo/1.0/direct';

  const query = new URLSearchParams({
    q: city,
    limit: '1',
    appId: OPEN_WEATHER_MAP_API_KEY,
  });

  const response = await fetch(`${url}?${query.toString()}`, {
    method: 'GET',
  });

  if (!response.ok) throw new Error(response.statusText);
  return (await response.json()) as WeatherCoordinates[];
};
