import { formatISO } from 'date-fns';
import { request, Response } from './utils/request';
import { url } from './common';

export interface ValuationItem {
  timestamp: string;
  value: number;
}

export interface Valuation {
  from: string;
  interval: string;
  items: ValuationItem[];
  length: number;
  to: string;
  total_count: number;
  units: {
    value: string;
  };
}

/**
 * Get valuations of a utility id for a building
 *
 * @param baseUri     The base uri of Qanteon instance
 * @param token       The token to request the data with
 * @param buildingId  The username provided
 * @param from        The from date for the valuations
 * @param to          The to date for the valuations
 * @param utilityId   The utilityId
 */
export const getValuation = async (
  baseUri: string,
  token: string,
  buildingId: string,
  from: Date,
  to: Date,
  utilityId: number,
): Promise<Response<Valuation>> =>
  request<Valuation>('GET', url(baseUri, '/valuations'), {
    token,
    query: {
      id: buildingId,
      from: formatISO(from),
      to: formatISO(to),
      utility_id: utilityId,
      interval: 'MONTH',
      fields: 'value',
    },
  });
