import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { injectGlobal } from '@emotion/css';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  initAppInsights,
  AppInsightsErrorBoundary,
} from '@kp/react-sdk/app-insights';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  CategoryScale,
  Title,
  Tooltip,
  TimeScale,
  Legend,
  BarElement,
} from 'chart.js';
import 'normalize.css';
import '@kp/react-ui/dist/styles.css';
import './utils/i18n';
import App from './App';
import { Loader } from './components/Loader';
import { SettingsProvider } from './contexts/settings-provider';
import { GenericPageProvider } from './contexts/generic-page-provider';
import { AuthProvider } from './contexts/auth-provider';
import { APPLICATIONINSIGHTS_CONNECTION_STRING } from './utils/env';
import { ErrorFallback } from './components/Errors';

ChartJS.register(
  LinearScale,
  CategoryScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

if (APPLICATIONINSIGHTS_CONNECTION_STRING) {
  initAppInsights('energy-dashboard-app', {
    connectionString: APPLICATIONINSIGHTS_CONNECTION_STRING,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: false,
    enableResponseHeaderTracking: false,
  });
}

declare module 'react-router-dom' {
  export function useParams<
    P extends Record<string, string> = {
      [key: string]: string;
    },
  >(): P;
}

injectGlobal({
  html: {
    boxSizing: 'border-box',
  },
  '*, *::before, *::after': {
    boxSizing: 'inherit',
  },
  ul: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  ol: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
});

const Root: React.FC = () => (
  <Suspense fallback={<Loader />}>
    <AppInsightsErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={console.warn}
    >
      <Router>
        <React.StrictMode>
          <AuthProvider>
            <SettingsProvider>
              <GenericPageProvider>
                <App />
              </GenericPageProvider>
            </SettingsProvider>
          </AuthProvider>
        </React.StrictMode>
      </Router>
    </AppInsightsErrorBoundary>
  </Suspense>
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Root />);
