import React, { useMemo } from 'react';
import { Toast, TypographyStyles } from '@kp/react-ui';
import { classNames, createClasses } from '../../utils/css';
import { Smile } from '../Smile';
import { Gauge } from '../Gauge/Gauge';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '32px',
    position: 'relative',
  },
  title: {
    ...TypographyStyles.bodyLarge,
    fontSize: '32px',
    lineHeight: '40px',
    fontWeight: 600,
    textAlign: 'center',
  },
  notifications: {
    position: 'absolute',
    bottom: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    right: 0,
  },
  icon: {
    textAlign: 'center',
  },
  smile: {
    width: '72px',
    height: '72px',
  },
});

interface GaugeCardProp {
  title?: string;
  unit?: string;
  benchmark?: number;
  scaleValue?: number;
  limitValue?: number;
  error?: Error;
  className?: string;
}

export const GaugeCard: React.FC<GaugeCardProp> = ({
  title,
  unit,
  benchmark,
  scaleValue,
  limitValue,
  error,
  className,
}) => {
  const percent = benchmark && scaleValue ? benchmark / scaleValue || 0 : 0;

  const smileType = useMemo(() => {
    if (percent < 0.33) {
      return 'green';
    }
    if (percent < 0.66) {
      return 'yellow';
    }
    return 'red';
  }, [percent]);

  return (
    <div className={classNames(className, classes.root)}>
      <div className={classes.notifications}>
        {error && <Toast variant="danger">{error.message}</Toast>}
      </div>
      <div className={classes.title}>{title}</div>
      {unit && scaleValue && benchmark ? (
        <Gauge
          benchmark={benchmark}
          scaleValue={scaleValue}
          limitValue={limitValue}
          unit={unit}
        />
      ) : null}
      <div className={classes.icon} data-testid={`${smileType}-smile`}>
        <Smile type={smileType} className={classes.smile} />
      </div>
    </div>
  );
};
