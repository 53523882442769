import React from 'react';
import { createClasses } from '../../utils/css';

const classes = createClasses({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  value: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 0,
    right: 0,
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '40px',
    color: '#1C1E21',
    textAlign: 'center',
  },
  minValue: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    position: 'absolute',
    bottom: '40px',
    left: '25%',
    color: '#007048',
  },
  maxValue: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
    position: 'absolute',
    bottom: '40px',
    textAlign: 'center',
    right: '25%',
    color: '#E60F3A',
  },
  unit: {
    position: 'absolute',
    bottom: '-10px',
    left: 0,
    right: 0,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '36px',
    color: '#1C1E21',
  },
});

const endAngle = 220;
const startAngle = -40;
const maxValue = 100;
const minValue = 0;

const valueToAngle = (value: number) => {
  const angleRange = endAngle - startAngle;
  const valueRange = maxValue - minValue;
  const angle = startAngle + ((value - minValue) / valueRange) * angleRange;
  return Math.round(angle);
};

const percentToRad = (percent: number) => {
  return (percent * Math.PI) / 180;
};

/**
 * Calculates the path of a needle
 *
 * @param percent
 * @param outerRadius
 * @param width
 * @param length       The length of the needle
 */
const calculateRotation = (
  percent: number,
  outerRadius: number,
  width: number,
  length: number,
) => {
  const needleLength = outerRadius * length;
  const needleRadius = width / 2;
  const theta = percentToRad(valueToAngle(percent));
  const centerPoint = [outerRadius / 2, outerRadius / 2];
  const topPoint = [
    centerPoint[0] - needleLength * Math.cos(theta),
    centerPoint[1] - needleLength * Math.sin(theta),
  ];
  const leftPoint = [
    centerPoint[0] - needleRadius * Math.cos(theta - Math.PI / 2),
    centerPoint[1] - needleRadius * Math.sin(theta - Math.PI / 2),
  ];
  const rightPoint = [
    centerPoint[0] - needleRadius * Math.cos(theta + Math.PI / 2),
    centerPoint[1] - needleRadius * Math.sin(theta + Math.PI / 2),
  ];
  return `M ${leftPoint[0]} ${leftPoint[1]} L ${topPoint[0]} ${topPoint[1]} A ${rightPoint[0]} ${rightPoint[1]}`;
};

interface GaugeProps {
  benchmark: number;
  limitValue?: number;
  scaleValue: number;
  unit: string;
}

export const Gauge: React.FC<GaugeProps> = ({
  benchmark,
  limitValue,
  scaleValue,
  unit,
}) => {
  const size = 260;

  const percentBenchmark = (benchmark / scaleValue || 0) * 100;

  const percentLimitValue = limitValue
    ? (limitValue / scaleValue || 0) * 100
    : 0;

  return (
    <div className={classes.root}>
      <svg
        width={size + 40}
        height={size + 40}
        viewBox={`-10 -10 ${size + 20} ${size + 20}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M212.731 212.731C217.808 217.808 226.097 217.846 230.642 212.287C244.082 195.85 253.334 176.315 257.502 155.362C262.518 130.144 259.944 104.006 250.104 80.2512C240.265 56.4968 223.603 36.1935 202.224 21.909C180.846 7.62437 155.712 1.17491e-06 130 0C104.288 -1.1749e-06 79.1543 7.62436 57.7759 21.9089C36.3975 36.1935 19.7351 56.4968 9.89566 80.2511C0.0562701 104.006 -2.51817 130.144 2.49791 155.362C6.66576 176.315 15.9185 195.85 29.3579 212.287C33.9025 217.846 42.1917 217.808 47.2685 212.731V212.731C52.3453 207.655 52.2702 199.467 47.8659 193.797C37.9896 181.081 31.1624 166.196 27.9983 150.289C23.9855 130.115 26.045 109.204 33.9165 90.2009C41.788 71.1974 55.118 54.9548 72.2207 43.5272C89.3234 32.0995 109.431 26 130 26C150.569 26 170.677 32.0995 187.779 43.5272C204.882 54.9548 218.212 71.1974 226.083 90.2009C233.955 109.204 236.015 130.115 232.002 150.289C228.838 166.196 222.01 181.081 212.134 193.797C207.73 199.467 207.655 207.655 212.731 212.731V212.731Z"
          fill="url(#paint0_angular_267_2660)"
          fillOpacity="1"
        />
        <path
          d={calculateRotation(percentBenchmark, size, 1, 0.51)}
          stroke="black"
          strokeWidth="12"
          strokeLinecap="round"
        />
        {limitValue && limitValue > 0 && (
          <path
            d={calculateRotation(percentLimitValue, size, 1, 0.39)}
            stroke="#236EF1"
            strokeWidth="6"
            strokeLinecap="round"
          />
        )}
        <circle
          cx={size / 2}
          cy={size / 2}
          r="67"
          fill="white"
          stroke="#C8CBD0"
          strokeWidth="2"
        />
        <defs>
          <radialGradient
            id="paint0_angular_267_2660"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(40 140) rotate(0) scale(200 300)"
          >
            <stop stopColor="#007048" />
            <stop offset="0.20" stopColor="#00A34B" />
            <stop offset="0.40" stopColor="#A29311" />
            <stop offset="0.60" stopColor="#D68000" />
            <stop offset="0.90" stopColor="#F15423" />
            <stop offset="1" stopColor="#E60F3A" />
          </radialGradient>
        </defs>
      </svg>
      <div className={classes.value}>{benchmark.toFixed(2)}</div>
      <div className={classes.minValue}>0</div>
      <div className={classes.maxValue}>{scaleValue}</div>
      <div className={classes.unit}>{unit}</div>
    </div>
  );
};
