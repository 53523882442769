import React from 'react';
import { Typography } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { createClasses } from '../../utils/css';
import { SelectItemRow } from '../../components/SelectItemRow';

const classes = createClasses({
  root: {
    height: '300px',
  },
  description: {
    marginTop: '24px',
  },
  input: {
    display: 'flex',
    marginTop: '10px',
    flexDirection: 'column',
    gap: '5px',
  },
});

interface SelectBuildingLocationProps {
  city: string;
  setCity: (city: string) => void;
}

export const SelectBuildingLocation: React.FC<SelectBuildingLocationProps> = ({
  city,
  setCity,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography variant="headlineSmall" component="div">
        {t('select.buildingAddress.title')}
      </Typography>
      <Typography
        variant="bodyMedium"
        className={classes.description}
        component="div"
      >
        {t('select.buildingAddress.description')}
      </Typography>
      <div className={classes.input}>
        <SelectItemRow
          label={t('select.buildingAddress.label.city')}
          value={city}
          setValue={setCity}
        />
      </div>
    </div>
  );
};
