import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Dashboard } from '../../../api';
import { GaugeCard } from '../../../components/GaugeCard';
import { useSettings } from '../../../contexts/settings-provider';
import { ChartCard } from './ChartCard';

interface MetricsControllerProps {
  className?: string;
}

export const MetricsController: React.FC<MetricsControllerProps> = ({
  className,
}) => {
  const { firstDashboard, secondDashboard, error } = useSettings();
  const { t } = useTranslation();

  const getTitle = (dashboard?: Dashboard): string => {
    return `${
      dashboard?.benchmark === 'CONSUMPTION'
        ? t('consumption.title')
        : dashboard?.benchmark
    } - ${dashboard?.utility.name} - ${dashboard?.reference.name}`;
  };

  return (
    <>
      <GaugeCard
        title={getTitle(firstDashboard)}
        unit={firstDashboard?.unit}
        scaleValue={firstDashboard?.scale_value}
        limitValue={firstDashboard?.values[1].limit_value}
        benchmark={firstDashboard?.values[1].value}
        className={className}
        error={error}
      />
      <GaugeCard
        title={getTitle(secondDashboard)}
        unit={secondDashboard?.unit}
        scaleValue={secondDashboard?.scale_value}
        limitValue={secondDashboard?.values[1].limit_value}
        benchmark={secondDashboard?.values[1].value}
        className={className}
        error={error}
      />
      <ChartCard className={className} />
    </>
  );
};
