import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Screen } from './features/Screen';
import { NotFoundHandler } from './contexts/generic-page-provider';

const App: React.FC = () => (
  <Layout>
    <Routes>
      <Route path="/" element={<Screen />} />
      <Route path="*" element={<NotFoundHandler />} />
    </Routes>
  </Layout>
);

export default App;
