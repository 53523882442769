import React from 'react';
import { Colors, LogoKP, TypographyStyles } from '@kp/react-ui';
import { createClasses } from '../../utils/css';
import { NameCard, WeatherCard, ClockCard } from './cards';
import { QanteonLogo } from '../../components/QanteonLogo/QanteonLogo';
import { MetricsController } from './cards/MetricsController';

//
// Configuration
//
const FOOTER_HEIGHT_PX = 120;
const GUTTER_PX = 16;
const INFO_HEIGHT_PERCENT = 25;
const NAME_CARD_WIDTH_PERCENT = 32;
const WEATHER_CARD_WIDTH_PERCENT = 45;
const CHART_CARD_WIDTH_PERCENT = 60;
const MARGIN_PX = 64;

//
// Derivatives
//
const METRICS_HEIGHT_PERCENT = 100 - INFO_HEIGHT_PERCENT;
const CLOCK_CARD_WIDTH_PERCENT =
  100 - WEATHER_CARD_WIDTH_PERCENT - NAME_CARD_WIDTH_PERCENT;
const METRICS_CARD_WIDTH_PERCENT = (100 - CHART_CARD_WIDTH_PERCENT) / 2;

const classes = createClasses({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  footer: {
    height: `${FOOTER_HEIGHT_PX}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...TypographyStyles.bodyLarge,
    fontWeight: 500,
    fontSize: '24px',
    color: Colors.Neutral.textStrong,
  },
  content: {
    height: `calc(100% - ${FOOTER_HEIGHT_PX}px)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: `${MARGIN_PX}px`,
  },
  logo: {
    width: '300px',
    height: '36px',
    marginLeft: '-16px',
  },
  logoQanteon: {
    marginRight: '20px',
  },
  info: {
    display: 'flex',
    height: `calc(${INFO_HEIGHT_PERCENT}% - ${GUTTER_PX}px)`,
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: `0 ${MARGIN_PX}px`,
  },
  metrics: {
    display: 'flex',
    height: `calc(${METRICS_HEIGHT_PERCENT}% - ${GUTTER_PX}px)`,
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: `0 ${MARGIN_PX}px`,
  },
  infoCard: {
    height: '100%',
    background: 'rgba(255,255,255,.96)',
    borderRadius: '24px',
    width: `calc(${WEATHER_CARD_WIDTH_PERCENT}% - ${GUTTER_PX * 1.5}px)`,
    '&:first-child': {
      width: `calc(${NAME_CARD_WIDTH_PERCENT}% - ${GUTTER_PX * 1.5}px)`,
    },
    '&:last-child': {
      width: `calc(${CLOCK_CARD_WIDTH_PERCENT}% - ${GUTTER_PX * 1.5}px)`,
    },
  },
  metricsCard: {
    height: '100%',
    background: '#ffffff',
    borderRadius: '24px',
    width: `calc(${METRICS_CARD_WIDTH_PERCENT}% - ${GUTTER_PX * 1.5}px)`,
    '&:last-child': {
      width: `calc(${CHART_CARD_WIDTH_PERCENT}% - ${GUTTER_PX * 1.5}px)`,
    },
  },
});

export const Screen: React.FC = () => {
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.info}>
          <NameCard className={classes.infoCard} />
          <WeatherCard className={classes.infoCard} />
          <ClockCard className={classes.infoCard} />
        </div>
        <div className={classes.metrics}>
          <MetricsController className={classes.metricsCard} />
        </div>
      </div>
      <div className={classes.footer}>
        <QanteonLogo className={classes.logoQanteon} />
        by
        <LogoKP background="dark" className={classes.logo} />
      </div>
    </div>
  );
};
