import { RequestError } from './RequestError';

type Method = 'GET' | 'POST' | 'PUT';

interface RequestOptions {
  query?: {};
  params?: {};
  token?: string;
  payload?: {};
}

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export interface Response<T> {
  data?: T;
  error?: RequestError;
}

export const request = async <T>(
  method: Method,
  url: string,
  options: RequestOptions = {},
): Promise<Response<T>> => {
  const uri = options.query
    ? `${url}?${new URLSearchParams(options.query).toString()}`
    : url;

  const response = await fetch(uri, {
    method,
    body: options.payload ? JSON.stringify(options.payload) : undefined,
    headers: {
      ...defaultHeaders,
      ...(options.token ? { 'x-kup-session': options.token } : {}),
      ...(options.params
        ? { 'x-kup-param': JSON.stringify(options.params) }
        : {}),
    },
  });

  if (!response.ok) {
    try {
      const data = (await response.json()) as RequestError;
      return { error: new RequestError(response.statusText, data) };
    } catch {
      return { error: new RequestError(response.statusText) };
    }
  }

  try {
    return { data: (await response.json()) as T };
  } catch {
    return {};
  }
};
