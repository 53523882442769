import React, { useCallback, useState } from 'react';
import { Button, Colors, ColorSelected } from '@kp/react-ui';
import { useIntervalWhen } from 'rooks';
import { useTranslation } from 'react-i18next';
import { createClasses, classNames, css } from '../../utils/css';
import { DialogLayout } from '../../components/DialogLayout';

const classes = createClasses({
  root: {
    padding: '48px 0',
    display: 'flex',
    flexDirection: 'row',
    color: Colors.Neutral.text,
  },
  actions: {
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    flexGrow: 1,
  },
  backButton: {
    marginRight: '12px',
  },
  nextButton: {
    marginLeft: '12px',
    position: 'relative',
  },
  autoCommitButton: {
    '&:after': {
      content: '""',
      backgroundColor: ColorSelected.backgroundStrongHover,
      borderRadius: '5px',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
    },
  },
  content: {
    zIndex: 1,
    position: 'relative',
  },
});

interface SelectStepTemplateProps {
  error?: Error | null;
  next: () => void;
  previous: () => void;
  children: React.ReactNode;
  isSummary: boolean;
}

export const SelectStepTemplate: React.FC<SelectStepTemplateProps> = ({
  error,
  next,
  previous,
  children,
  isSummary,
}) => {
  const { t } = useTranslation();

  const handleClickNext = useCallback(() => {
    next();
  }, [next]);

  const handleClickBack = useCallback(() => {
    previous();
  }, [previous]);

  const [progress, setProgress] = useState(0);

  const continueInMs = 10000; // 10 seconds
  const refreshHerz = 50; // 50 times per second

  const interval = 1000 / refreshHerz;

  useIntervalWhen(
    () => {
      progress < 100
        ? isSummary && setProgress(progress + 100 / (continueInMs / interval))
        : next();
    },
    interval,
    true,
  );

  return (
    <DialogLayout className={classes.root} error={error}>
      {children}
      <div className={classes.actions}>
        <Button
          className={classNames(classes.button, classes.backButton)}
          size="large"
          onClick={handleClickBack}
          variant="secondary"
        >
          {isSummary
            ? t('select.navigation.button.edit')
            : t('select.navigation.button.back')}
        </Button>
        <Button
          className={classNames(
            classes.button,
            classes.nextButton,
            isSummary && classes.autoCommitButton,
            css({ '&:after': { width: `${progress}%` } }),
          )}
          size="large"
          onClick={handleClickNext}
        >
          <div className={classes.content}>
            {isSummary
              ? t('select.navigation.button.dashboard')
              : t('select.navigation.button.next')}
          </div>
        </Button>
      </div>
    </DialogLayout>
  );
};
