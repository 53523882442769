import React from 'react';

interface QanteonLogoProps extends React.ComponentProps<'svg'> {}

export const QanteonLogo: React.FC<QanteonLogoProps> = (props) => (
  <svg
    width="180"
    height="58"
    viewBox="0 0 180 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M89.5359 13.5708L87.1393 14.1234V16.4051V18.6869H85.6632H84.187V20.2913V21.8956H85.6632H87.1393V28.7943C87.1393 32.9478 87.2088 35.9783 87.313 36.3705C87.5735 37.3153 88.8759 38.7057 89.9353 39.1692C91.6198 39.9179 94.0337 39.8466 96.6387 38.9731C97.2639 38.7592 97.8022 38.4918 97.8543 38.367C97.9064 38.2422 97.7675 37.6183 97.5765 36.9944L97.1944 35.8535L96.2045 36.21C94.5374 36.7805 93.287 36.3883 92.7313 35.1226C92.4708 34.5522 92.436 33.5718 92.3839 28.1883L92.3318 21.9134L94.9021 21.86L97.4723 21.8065V20.2913V18.776L94.9194 18.7226L92.3492 18.6691V15.8347C92.3492 13.6599 92.2971 12.9825 92.1408 13.0003C92.0192 13.0182 90.8383 13.2677 89.5359 13.5708Z"
      fill={props.color || '#fff'}
    />
    <path
      d="M21.234 18.7759C17.1356 19.5425 14.3396 22.2164 13.3323 26.2808C12.6898 28.8656 12.985 31.7356 14.1659 34.1956C14.7216 35.3899 15.2253 35.9782 18.7159 39.6147C20.8693 41.843 23.1443 44.1604 23.7695 44.7309L24.9331 45.7826L26.8955 45.337C27.9895 45.0874 28.9968 44.8378 29.1183 44.7843C29.292 44.7309 27.7811 43.0552 24.5857 39.7574C21.5466 36.62 19.6537 34.5343 19.4279 34.053C18.7854 32.7338 18.4901 31.2008 18.4728 29.1151C18.4728 28.0277 18.577 26.7086 18.6985 26.1738C19.2369 23.9634 20.5915 22.1273 21.9981 21.7173C23.6827 21.236 25.7145 21.8242 26.6176 23.0364C27.7985 24.6229 28.389 27.1899 28.2153 29.9708C28.0069 33.126 26.9997 35.3365 25.3498 36.1565C24.8636 36.406 24.4642 36.6378 24.4642 36.6913C24.4468 36.7447 24.9852 37.333 25.6451 38.0104L26.8434 39.2226L28.1806 38.563C31.5496 36.9052 33.651 33.233 33.651 29.0438C33.651 26.0134 32.6785 23.5534 30.6987 21.539C28.3021 19.0968 24.8636 18.0807 21.234 18.7759Z"
      fill={props.color || '#fff'}
    />
    <path
      d="M44.9217 18.6868C44.3833 18.7759 43.4455 19.0255 42.8377 19.2216C41.7436 19.5959 39.5034 20.8437 39.295 21.1824C39.1908 21.3607 40.6669 23.6781 40.8753 23.6781C40.9274 23.6781 41.3268 23.4285 41.7784 23.1077C43.1329 22.1451 44.3486 21.7351 45.7726 21.7172C46.4673 21.7172 47.2835 21.8242 47.5961 21.9668C48.5339 22.359 49.4196 23.5533 49.7322 24.819C49.819 25.2112 49.7322 25.2468 47.7698 25.7281C41.6915 27.1899 38.9129 28.9903 37.9925 32.0386C37.5583 33.4647 37.5583 34.2134 37.9925 35.6751C39.3297 40.1139 45.0259 41.0586 48.8291 37.4934C49.2806 37.0477 49.6974 36.6912 49.7322 36.6912C49.7669 36.6912 49.8016 36.9051 49.8016 37.1547C49.8016 37.4043 49.8884 37.9569 49.9926 38.4025L50.1837 39.1869H52.9449C54.4732 39.1869 55.7062 39.1334 55.7062 39.0443C55.6888 38.973 55.5846 38.4382 55.4457 37.8499C55.2547 37.0477 55.1852 35.5325 55.1852 31.8782C55.1678 25.9777 54.9942 23.8029 54.4384 22.5372C53.6396 20.7546 52.3718 19.685 50.2705 19.0077C49.0201 18.6155 46.3631 18.455 44.9217 18.6868ZM49.8537 29.4181C49.9753 30.8621 49.7148 32.4664 49.1764 33.6251C48.6207 34.7838 47.4919 35.693 46.2068 35.996C44.9911 36.2812 43.8971 35.889 43.2892 34.9264C42.1604 33.1616 42.7509 31.1473 44.748 29.9529C45.4253 29.5429 48.9507 28.3486 49.5585 28.3129C49.6801 28.3129 49.8016 28.7764 49.8537 29.4181Z"
      fill={props.color || '#fff'}
    />
    <path
      d="M71.0408 18.776C69.7209 19.079 68.5226 19.7207 67.307 20.7903L66.3866 21.5925L66.2998 20.1842L66.2129 18.776L63.6601 18.7225L61.0898 18.669V28.9369V39.1869H63.6774H66.2824L66.3345 32.3595C66.3866 25.8529 66.4039 25.5142 66.7513 24.7477C67.5328 23.0721 68.9047 22.2521 70.9713 22.2521C72.4474 22.2699 73.4026 22.6977 74.0972 23.696C75.1045 25.1042 75.1566 25.5856 75.1566 32.716V39.1869H77.7789H80.3839L80.3318 31.7356C80.2623 23.5355 80.2449 23.4821 79.1161 21.5568C78.4562 20.416 77.3274 19.5247 75.9207 19.0255C74.6182 18.562 72.4301 18.4373 71.0408 18.776Z"
      fill={props.color || '#fff'}
    />
    <path
      d="M107.024 18.7759C103.169 19.5959 100.373 22.5194 99.5737 26.566C99.0527 29.1507 99.3653 31.486 100.546 33.9995C101.779 36.6556 104.228 38.6699 107.111 39.383C108.431 39.7217 111.609 39.6682 113.067 39.3117C114.387 38.973 117.079 37.8499 117.34 37.5112C117.461 37.3508 117.305 36.8873 116.819 35.9425L116.124 34.5699L115.082 35.1047C113.658 35.8356 111.834 36.2099 110.41 36.0851C107.58 35.8356 105.339 33.4647 104.923 30.2916L104.801 29.3825H111.626H118.451L118.329 28.0634C118.104 25.532 117.079 22.6798 115.898 21.2359C115.099 20.2911 113.797 19.4533 112.46 19.0255C111.018 18.562 108.57 18.455 107.024 18.7759ZM110.897 21.3785C112.112 21.9846 112.772 23.2681 113.085 25.6746L113.172 26.352H109.038H104.905L105.044 25.4607C105.565 22.0381 108.326 20.1129 110.897 21.3785Z"
      fill={props.color || '#fff'}
    />
    <path
      d="M129.652 18.6867C124.425 19.6672 121.195 23.6068 121.177 29.0438C121.177 34.3203 124.338 38.4203 129.201 39.4364C136.078 40.8803 141.826 36.1386 141.843 29.0081C141.861 23.2146 137.762 18.865 132.049 18.5976C131.18 18.562 130.104 18.5976 129.652 18.6867ZM132.813 21.7172C134.254 22.1272 135.366 23.4642 135.974 25.532C136.425 27.0472 136.512 30.4699 136.13 32.0564C135.609 34.2134 134.584 35.7286 133.247 36.3525C132.327 36.7803 130.694 36.7803 129.774 36.3525C128.836 35.9247 127.69 34.5699 127.256 33.3577C126.405 31.0225 126.405 27.2255 127.256 24.9081C127.69 23.6603 128.819 22.3055 129.774 21.8776C130.538 21.5211 131.875 21.4498 132.813 21.7172Z"
      fill={props.color || '#fff'}
    />
    <path
      d="M156.813 18.6868C155.632 18.9364 154.243 19.6673 153.045 20.6477L151.933 21.5568L151.881 20.1664L151.829 18.7759L149.276 18.7225L146.706 18.669V28.9369V39.1869H149.311H151.899L151.951 32.3595L152.003 25.5499L152.489 24.5516C152.993 23.4821 153.844 22.7334 154.886 22.4303C155.858 22.1451 157.647 22.2164 158.428 22.5908C159.314 23.0008 159.592 23.3395 160.2 24.6586L160.686 25.7282L160.738 32.4486L160.79 39.1869H163.395H166L165.948 31.8247C165.879 24.8547 165.861 24.4268 165.496 23.3216C164.767 21.0577 163.551 19.7386 161.52 19.0255C160.373 18.6155 157.925 18.4373 156.813 18.6868Z"
      fill={props.color || '#fff'}
    />
  </svg>
);
