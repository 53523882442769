import React from 'react';
import { IconBaseProps } from 'react-icons';
import {
  WiDaySunny,
  WiNightClear,
  WiCloudRefresh,
  WiDaySunnyOvercast,
  WiNightPartlyCloudy,
  WiNightCloudy,
  WiDayCloudy,
  WiCloudy,
  WiDayRain,
  WiNightRain,
  WiDayShowers,
  WiNightShowers,
  WiDayThunderstorm,
  WiNightThunderstorm,
  WiDaySnow,
  WiNightSnow,
  WiDust,
} from 'react-icons/wi';

type WeatherIconProps = IconBaseProps & {
  type: string;
};

export const WeatherIcon: React.FC<WeatherIconProps> = ({
  type,
  ...iconProps
}) => {
  switch (type) {
    case '01d': {
      // clear sky day
      return <WiDaySunny {...iconProps} />;
    }
    case '01n': {
      // clear sky night
      return <WiNightClear {...iconProps} />;
    }
    case '02d': {
      // few clouds day
      return <WiDaySunnyOvercast {...iconProps} />;
    }
    case '02n': {
      // few clouds night
      return <WiNightPartlyCloudy {...iconProps} />;
    }
    case '03d': {
      // scattered clouds day
      return <WiDayCloudy {...iconProps} />;
    }
    case '03n': {
      // scattered clouds night
      return <WiNightCloudy {...iconProps} />;
    }
    case '04d': {
      // broken clouds day
      return <WiCloudy {...iconProps} />;
    }
    case '04n': {
      // broken clouds night
      return <WiCloudy {...iconProps} />;
    }
    case '09d': {
      // shower rain day
      return <WiDayShowers {...iconProps} />;
    }
    case '09n': {
      // shower rain night
      return <WiNightShowers {...iconProps} />;
    }
    case '10d': {
      // rain day
      return <WiDayRain {...iconProps} />;
    }
    case '10n': {
      // rain night
      return <WiNightRain {...iconProps} />;
    }
    case '11d': {
      // thunderstorm day
      return <WiDayThunderstorm {...iconProps} />;
    }
    case '11n': {
      // thunderstorm night
      return <WiNightThunderstorm {...iconProps} />;
    }
    case '13d': {
      // snow day
      return <WiDaySnow {...iconProps} />;
    }
    case '13n': {
      // snow night
      return <WiNightSnow {...iconProps} />;
    }
    case '50d': {
      // mist day
      return <WiDust {...iconProps} />;
    }
    case '50n': {
      // mist night
      return <WiDust {...iconProps} />;
    }
    default: {
      return <WiCloudRefresh {...iconProps} />;
    }
  }
};
