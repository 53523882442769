import { Card, DescriptionList, FormControl, Typography } from '@kp/react-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses } from '../../utils/css';

const classes = createClasses({
  root: {},
  card: {
    margin: '24px 0 24px 0',
    height: '235px',
    overflowY: 'scroll',
  },
});

interface SummaryProps {
  buildingId: string;
  buildingName: string;
  city: string;
}

export const Summary: React.FC<SummaryProps> = ({
  buildingId,
  buildingName,
  city,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Typography variant="headlineSmall" component="div">
        {t('select.summary.title')}
      </Typography>
      <Card className={classes.card}>
        <FormControl>
          <DescriptionList
            term={t('select.building.title')}
            description={buildingId}
          />
        </FormControl>
        <FormControl>
          <DescriptionList
            term={t('select.buildingName.title')}
            description={buildingName}
          />
        </FormControl>
        <FormControl>
          <DescriptionList
            term={t('select.buildingAddress.label.city')}
            description={city}
          />
        </FormControl>
      </Card>
    </div>
  );
};
