import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, Typography } from '@kp/react-ui';
import { createClasses } from '../../utils/css';
import { ErrorLayout } from '../ErrorLayout';

const classes = createClasses({
  title: {
    marginTop: '16px',
  },
  message: {
    color: Colors.Neutral.backgroundInverted,
    marginTop: '16px',
  },
});

interface ErrorGenericProps {
  error?: Error;
}

export const ErrorGeneric: React.FC<ErrorGenericProps> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <ErrorLayout title={t('errors.generic.title')}>
      <Typography
        variant="bodyMedium"
        component="div"
        className={classes.title}
      >
        {t('errors.generic.message')}
      </Typography>
      <Typography
        variant="bodySmall"
        component="div"
        className={classes.message}
      >
        {error?.message}
      </Typography>
    </ErrorLayout>
  );
};
