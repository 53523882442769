import { format } from 'date-fns';
import { request, Response } from './utils/request';
import { url } from './common';

export interface Dashboard {
  benchmark: string;
  id: string;
  limit_value: number;
  period: string;
  reference: {
    id: number;
    name: string;
    unit: string;
  };
  utility: {
    id: number;
    name: string;
    unit: string;
  };
  values: Array<{
    from: Date;
    to: Date;
    value: number;
    limit_value: number;
  }>;
  scale_value: number;
  unit: string;
}
export interface Dashboards {
  date: Date;
  dashboards: Array<Dashboard>;
}

/**
 * Get benchmark for a utility id and a reference id
 *
 * @param baseUri     The base uri of Qanteon instance
 * @param token       The token to request the data with
 * @param buildingId  The username provided
 * @param date        The date to get the benchmark for
 */
export const getDashboards = async (
  baseUri: string,
  token: string,
  buildingId: string,
  date: Date,
): Promise<Response<Dashboards>> =>
  request<Dashboards>('GET', url(baseUri, '/dashboards'), {
    token,
    query: {
      period_previous: 2,
      period_next: 1,
      facility_id: buildingId,
      date: format(date, 'yyyy-MM-dd'),
      climate_corrected: false,
    },
  });
