import React from 'react';
import { Typography } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { createClasses } from '../../utils/css';
import { SelectItemRow } from '../../components/SelectItemRow';

const classes = createClasses({
  root: {
    paddingTop: '20px',
  },
  description: {
    marginTop: '8px',
  },
  input: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
});

interface SelectQanteonProps {
  qanteonUri: string;
  setQanteonUri: (qanteonUri: string) => void;
}

export const SelectQanteon: React.FC<SelectQanteonProps> = ({
  qanteonUri,
  setQanteonUri,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography variant="headlineSmall" component="div">
        {t('select.qanteon.title')}
      </Typography>
      <Typography
        variant="bodyMedium"
        className={classes.description}
        component="div"
      >
        {t('select.qanteon.description')}
      </Typography>
      <div className={classes.input}>
        <SelectItemRow
          label={t('select.qanteon.label.uri')}
          value={qanteonUri}
          setValue={setQanteonUri}
        />
      </div>
    </div>
  );
};
