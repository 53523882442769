import { IconButton, Icons, Input } from '@kp/react-ui';
import React from 'react';
import { createClasses } from '../../utils/css';

const classes = createClasses({
  inputRow: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '5px',
  },
});

interface SelectItemRowProps {
  label: string;
  value: string;
  setValue: (value: string) => void;
}

export const SelectItemRow: React.FC<SelectItemRowProps> = ({
  label,
  value,
  setValue,
}) => {
  const handleClickDelete = () => {
    setValue('');
  };

  return (
    <div className={classes.inputRow}>
      <Input
        onChange={(event) => setValue(event.target.value)}
        fullWidth
        value={value}
        label={label}
        size="large"
      />
      <IconButton
        data-testid="clear-button"
        variant="tertiary"
        component={'button'}
        disabled={!value}
        icon={Icons.IconTrash}
        onClick={handleClickDelete}
      />
    </div>
  );
};
