import React, { useMemo, useState } from 'react';
import { Colors, Toast, TypographyStyles } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {
  subMonths,
  endOfMonth,
  isBefore,
  isAfter,
  getMonth,
  subYears,
} from 'date-fns';
import { createClasses, classNames } from '../../../utils/css';
import { useLocaleDateFns } from '../../../hooks/date-fns';
import { useSettings } from '../../../contexts/settings-provider';

const classes = createClasses({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '32px',
    position: 'relative',
  },
  notifications: {
    position: 'absolute',
    bottom: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    right: 0,
  },
  chart: {
    height: '40%',
  },
  title: {
    ...TypographyStyles.bodyLarge,
    fontSize: '32px',
    lineHeight: '40px',
    fontWeight: 600,
    textAlign: 'center',
  },
});

export const ChartCard: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();
  const [now] = useState(new Date());
  const { format } = useLocaleDateFns();

  const { consumption, error } = useSettings();

  const { currentEndDate } = useMemo(
    () => ({
      currentEndDate: endOfMonth(now),
    }),
    [now],
  );

  const data = useMemo(
    () =>
      consumption.map(({ timestamp, value }) => ({
        x: new Date(timestamp),
        y: value,
      })),
    [consumption],
  );

  const [comparisonData, differenceData, max] = useMemo(() => {
    const previousPeriodData = data.filter(
      ({ x }) =>
        isBefore(x, subYears(currentEndDate, 1)) &&
        isAfter(x, subYears(currentEndDate, 2)),
    );

    const currentPeriodData = data.filter(({ x }) =>
      isAfter(x, subYears(currentEndDate, 1)),
    );

    const labels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
      .map((month) => format(subMonths(currentEndDate, month), 'MMM'))
      .reverse();

    const diffData = labels.map((month, index) => {
      const currentMonth = getMonth(subMonths(currentEndDate, 11 - index));
      const currentPeriodValue = currentPeriodData.find(
        ({ x }) => getMonth(x) === currentMonth,
      )?.y;
      const previousPeriodValue = previousPeriodData.find(
        ({ x }) => getMonth(x) === currentMonth,
      )?.y;
      return currentPeriodValue && previousPeriodValue
        ? currentPeriodValue - previousPeriodValue
        : 0;
    });

    return [
      {
        datasets: [
          {
            label: 'Previous Period',
            backgroundColor: '#7890ba',
            data: labels.map(
              (month, index) =>
                previousPeriodData.find(
                  ({ x }) =>
                    getMonth(x) ===
                    getMonth(subMonths(currentEndDate, 11 - index)),
                )?.y || 0,
            ),
          },
          {
            label: 'Current Period',
            backgroundColor: '#3d71f8',
            data: labels.map(
              (month, index) =>
                currentPeriodData.find(
                  ({ x }) =>
                    getMonth(x) ===
                    getMonth(subMonths(currentEndDate, 11 - index)),
                )?.y || 0,
            ),
          },
        ],
        labels,
      },
      {
        datasets: [
          {
            label: 'Difference',
            data: diffData,
            backgroundColor: diffData.map((value) =>
              value > 0 ? '#e55e2a' : '#39d58d',
            ),
          },
        ],
        labels,
      },
      Math.max(Math.min(...diffData), Math.max(...diffData) * -1),
    ];
  }, [data, currentEndDate, format]);

  return (
    <div className={classNames(className, classes.root)}>
      <div className={classes.notifications}>
        {error && <Toast variant="danger">{error.message}</Toast>}
      </div>
      <div className={classes.title}>{t('chart.title')}</div>
      <div className={classes.chart} data-testid="comparison">
        {consumption.length > 0 && (
          <Bar
            id="comparison"
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  grid: {
                    display: false,
                    drawTicks: false,
                    borderWidth: 2,
                  },
                  ticks: {
                    padding: 16,
                    font: {
                      size: 24,
                      family: 'Inter',
                      weight: '500',
                    },
                    color: Colors.Neutral.text,
                  },
                },
                y: {
                  display: true,
                  grid: {
                    drawTicks: false,
                    drawOnChartArea: false,
                    borderWidth: 2,
                  },
                  ticks: {
                    display: true,
                    padding: 16,
                    font: {
                      size: 24,
                      family: 'Inter',
                      weight: '500',
                    },
                    color: Colors.Neutral.text,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
              },
            }}
            data={comparisonData}
          />
        )}
      </div>
      <div className={classes.chart} data-testid="difference">
        {consumption.length > 0 && (
          <Bar
            id="difference"
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  ticks: {
                    display: false,
                  },
                },
                y: {
                  display: true,
                  ticks: {
                    display: true,
                    padding: 16,
                    font: {
                      size: 24,
                      family: 'Inter',
                      weight: '500',
                    },
                    color: Colors.Neutral.text,
                  },
                  min: -1 * max,
                  max,
                  grid: {
                    drawTicks: false,
                    drawOnChartArea: false,
                    borderWidth: 2,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
              },
            }}
            data={differenceData}
          />
        )}
      </div>
    </div>
  );
};
