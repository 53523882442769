import React from 'react';
import { createClasses, classNames } from '../../utils/css';
import backgroundUrl from './background.jpg';

const classes = createClasses({
  root: {
    width: '100vw',
    height: '100vh',
    background: `url(${backgroundUrl}) no-repeat`,
    backgroundSize: 'cover',
  },
});

interface LayoutProps {
  children?: React.ReactNode;
  className?: string;
}

export const Layout: React.FC<LayoutProps> = ({ children, className }) => (
  <div className={classNames(classes.root, className)}>{children}</div>
);
