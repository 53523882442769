import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import usePersistedStateSync from '@hoeks/use-persisted-state-sync';
import { getLanguage, postSession, putSession } from '../api';
import { AuthenticateLayout } from '../features/Authenticate';
import { RequestError } from '../api/utils/RequestError';
import { Login } from '../features/Authenticate/Login';
import { SelectQanteon } from '../features/Authenticate/SelectQanteon';

type AuthStateType = 'instance' | 'login' | 'initialized';

interface AuthContextValue {
  baseUri: string;
  initialized: boolean;
  withToken: (callback: (token: string) => Promise<void>) => Promise<boolean>;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextValue>({
  baseUri: '',
  initialized: false,
  withToken: () => Promise.resolve(false),
  signOut: () => {},
});

export const useAuth = (): AuthContextValue => useContext(AuthContext);

interface AuthProviderProps {
  children?: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [baseUri, setBaseUri] = usePersistedStateSync('', 'qanteon-uri');
  const [error, setError] = useState<RequestError | null>(null);
  const [currentState, setCurrentState] = useState<AuthStateType>('instance');

  const setBaseUriAndLanguage = useCallback(() => {
    getLanguage(baseUri)
      .then((response) => {
        if (response?.data?.systemlanguage) {
          setError(null);
          setCurrentState('login');
          i18next.changeLanguage(response?.data?.systemlanguage.split('_')[0]);
        } else {
          setError(
            response.error || new Error('Unable to detect the language.'),
          );
        }
        return null;
      })
      .catch((err) => {
        setError(err);
        console.error(err);
      });
  }, [baseUri]);

  const login = useCallback(async () => {
    return postSession(baseUri, username, password).then((response) => {
      if (response.error) throw response.error;
      if (!response?.data) throw new Error('Unable to login');
      return {
        session: response.data.session_id,
        token: response.data.token,
      };
    });
  }, [baseUri, username, password]);

  const logout = useCallback(
    async (token: string, session: number) => {
      const response = await putSession(baseUri, token, session);
      if (response?.error) return false;
      return true;
    },
    [baseUri],
  );

  const withToken = useCallback(
    (callback: (token: string) => Promise<void>) =>
      login().then(async (credentials) => {
        if (!credentials) {
          throw new Error('No credentials found');
        }
        const { session, token } = credentials;
        try {
          await callback.call('', token);
          return await logout(token, session);
        } catch {
          return await logout(token, session);
        }
      }),
    [login, logout],
  );

  const signOut = useCallback(() => {
    setCurrentState('instance');
    setUsername('');
    setPassword('');
  }, []);

  const handleClick = useCallback(() => {
    switch (currentState) {
      case 'instance': {
        if (baseUri === '')
          setError(new Error('Please enter the link to Qanteon instance'));
        else setBaseUriAndLanguage();
        break;
      }
      case 'login': {
        withToken(() => Promise.resolve())
          .then(() => {
            setCurrentState('initialized');
            return null;
          })
          .catch(setError);
        break;
      }
    }
  }, [setBaseUriAndLanguage, currentState, baseUri, withToken]);

  const value = useMemo(
    () => ({
      currentState,
      baseUri,
      initialized: currentState === 'initialized',
      withToken,
      signOut,
    }),
    [currentState, baseUri, withToken, signOut],
  );

  return (
    <AuthContext.Provider value={value}>
      {currentState === 'initialized' ? (
        children
      ) : (
        <AuthenticateLayout
          handleClick={handleClick}
          buttonText={
            currentState === 'login'
              ? t('login.button.signin')
              : t('select.navigation.button.next')
          }
          error={error}
        >
          {currentState === 'login' ? (
            <Login
              username={username}
              password={password}
              setUsername={setUsername}
              setPassword={setPassword}
            />
          ) : (
            <SelectQanteon qanteonUri={baseUri} setQanteonUri={setBaseUri} />
          )}
        </AuthenticateLayout>
      )}
    </AuthContext.Provider>
  );
};
