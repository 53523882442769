import React, { useCallback, useState } from 'react';
import { TypographyStyles, Icons, Icon, Colors } from '@kp/react-ui';
import { useEffectOnceWhen, useIntervalWhen } from 'rooks';
import { createClasses, classNames } from '../../../utils/css';
import { UmbrellaIcon } from './UmbrellaIcon';
import { getCoordinates, getWeather, WeatherData } from '../../../api';
import { WeatherIcon } from '../../../components/WeatherIcon';
import { useSettings } from '../../../contexts/settings-provider';
import i18n from '../../../utils/i18n';

//
// Configuration
//
const LEFT_COLUMN_WIDTH_PERCENT = 18;
const GUTTER_PX = 82;

//
// Derivatives
//
const RIGHT_COLUMN_WIDTH_PERCENT = 100 - LEFT_COLUMN_WIDTH_PERCENT;

export const classes = createClasses({
  root: {
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: `calc(${RIGHT_COLUMN_WIDTH_PERCENT}% - ${GUTTER_PX}px)`,
    '&:first-child': {
      width: `${LEFT_COLUMN_WIDTH_PERCENT}%`,
    },
  },
  value: {
    ...TypographyStyles.bodyLarge,
    marginLeft: 0,
    fontSize: '48px',
    lineHeight: '56px',
    fontWeight: 600,
    flexGrow: 1,
    textAlign: 'right',
  },
  icon: {
    color: Colors.Neutral.icon,
  },
  description: {
    ...TypographyStyles.bodyLarge,
    fontSize: '32px',
    lineHeight: '40px',
    fontWeight: 600,
    flexGrow: 1,
    textAlign: 'left',
    marginLeft: '32px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
});

interface WeatherCardProps {
  className?: string;
}

interface Coord {
  lat: number;
  lon: number;
}

export const WeatherCard: React.FC<WeatherCardProps> = ({ className }) => {
  const [weather, setWeather] = useState<WeatherData | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { buildingName, city } = useSettings();
  const [coord, setCoord] = useState<Coord>();

  const update = useCallback(() => {
    coord &&
      getWeather(coord.lat, coord.lon, i18n.language)
        .then((data) => {
          setWeather(data);
          return null;
        })
        .catch((err) => {
          setError(err);
        });
  }, [coord]);

  useIntervalWhen(update, 1000 * 60 * 60, !!coord, true);

  useEffectOnceWhen(() => {
    getCoordinates(city)
      .then((coords) => {
        setCoord(coords[0]);
        return null;
      })
      .catch((err) => {
        setError(err);
      });
  });

  if (!weather) {
    return (
      <div className={classNames(className, classes.root)}>
        {error && <div className={classes.value}>{error.message}</div>}
      </div>
    );
  }

  const { current, daily } = weather;
  const icon = current?.weather?.[0]?.icon || '';
  const text = current?.weather?.[0]?.description || '';
  const temperature = current?.temp || 0;
  const precipitation = 100 * (daily?.[0].pop || 0);

  return (
    <div className={classNames(className, classes.root)}>
      {error ? (
        <div className={classes.value}>{error.message}</div>
      ) : (
        <>
          <div className={classes.row}>
            <div className={classes.cell}>
              <Icon
                icon={Icons.IconThermostat}
                size={56}
                className={classes.icon}
              />
              <div className={classes.value} data-testid="temperature">
                {Math.round(temperature)}°C
              </div>
            </div>
            <div className={classes.cell}>
              <span className={classes.icon}>
                <WeatherIcon
                  type={icon}
                  size="56px"
                  className={classes.icon}
                  data-testid="icon"
                />
              </span>
              <div className={classes.description} data-testid="condition">
                {`${text[0].toUpperCase()}${text.slice(1)}`}
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.cell}>
              <span>
                <UmbrellaIcon className={classes.icon} />
              </span>
              <div className={classes.value} data-testid="precipitation">
                {precipitation}%
              </div>
            </div>
            <div className={classes.cell}>
              <Icon
                icon={Icons.IconLocationOn}
                size={56}
                className={classes.icon}
              />
              <div className={classes.description}>
                {buildingName}, {city}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
