import React, { useState } from 'react';
import { TypographyStyles } from '@kp/react-ui';
import { format } from 'date-fns';
import { useIntervalWhen } from 'rooks';
import { createClasses, classNames } from '../../../utils/css';

const classes = createClasses({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  value: {
    ...TypographyStyles.bodyLarge,
    fontSize: '96px',
    lineHeight: '160px',
    fontWeight: 700,
    fontFeatureSettings: "'tnum' on, 'lnum' on",
  },
});

interface ClockCardProps {
  className?: string;
}

export const ClockCard: React.FC<ClockCardProps> = ({ className }) => {
  const [date, setDate] = useState(new Date());
  useIntervalWhen(() => setDate(new Date()), 60 * 1000, true);
  return (
    <div className={classNames(className, classes.root)}>
      <div className={classes.value}>{format(date, 'H:mm')}</div>
    </div>
  );
};
