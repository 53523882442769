import { css, cx, keyframes } from '@emotion/css';
import type { CSSObject } from '@emotion/css';

export const createClasses = <T>(
  obj: Record<keyof T, CSSObject>,
): Record<keyof T, string> => {
  return Object.keys(obj).reduce<Record<keyof T, string>>(
    (result: Record<keyof T, string>, item: string) => ({
      ...result,
      [item]: css(obj[item as keyof T]),
    }),
    {} as Record<keyof T, string>,
  );
};

export { cx as classNames };

export const animations = {
  slideRight: keyframes({
    to: {
      backgroundPosition: `
      550% 0,
      0 0;
      `,
    },
  }),
  bounceInTop: keyframes({
    '0%': {
      transform: 'translateY(-100px)',
      animationTimingFunction: 'ease-in',
      opacity: 0,
    },
    '38%': {
      transform: 'translateY(0)',
      animationTimingFunction: 'ease-out',
      opacity: 1,
    },
    '45%': {
      transform: 'translateY(-10px)',
      animationTimingFunction: 'ease-in',
    },
    '55%': {
      transform: 'translateY(0)',
      animationTimingFunction: 'ease-out',
    },
    '100%': {
      transform: 'translateY(0)',
      animationTimingFunction: 'ease-out',
    },
  }),
};

export { css };
