import urljoin from 'url-join';

/**
 * Create url that points to the service
 *
 * @param baseUri  The base uri of Qanteon instance
 * @param segment  The segment path of the url
 */
export const url = (baseUri: string, ...segments: string[]) =>
  urljoin(baseUri, 'api', ...segments);
