import React from 'react';
import { Typography } from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { createClasses } from '../../utils/css';
import { SelectItemRow } from '../../components/SelectItemRow';

const classes = createClasses({
  root: {
    height: '300px',
  },
  description: {
    marginTop: '24px',
  },
  input: {
    marginTop: '10px',
  },
});

interface SelectBuildingIdProps {
  buildingId: string;
  setBuildingId: (buildingId: string) => void;
}

export const SelectBuilding: React.FC<SelectBuildingIdProps> = ({
  buildingId,
  setBuildingId,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography variant="headlineSmall" component="div">
        {t('select.building.title')}
      </Typography>
      <Typography
        variant="bodyMedium"
        className={classes.description}
        component="div"
      >
        {t('select.building.description')}
      </Typography>
      <div className={classes.input}>
        <SelectItemRow
          label={t('select.building.label.buildingId')}
          value={buildingId}
          setValue={setBuildingId}
        />
      </div>
    </div>
  );
};
