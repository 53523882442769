import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { registerLocale } from '@kp/react-ui';
import { es, enUS, de } from 'date-fns/locale';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: ['en'],
    debug: false,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
  });

registerLocale('es', es);
registerLocale('en', enUS);
registerLocale('de', de);

export default i18n;
