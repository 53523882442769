import { request, Response } from './utils/request';
import { url } from './common';

export interface Language {
  systemlanguage: string;
}
/**
 * Get system language for current Qanteon instance
 *
 * @param baseUri     The base uri of Qanteon instance
 */
export const getLanguage = async (
  baseUri: string,
): Promise<Response<Language>> =>
  request<Language>('GET', url(baseUri, '/systemlanguage'));
