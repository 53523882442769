import { request, Response } from './utils/request';
import { url } from './common';

export interface Session {
  token: string;
  session_id: number;
}

export interface PutSession {
  session_id: string;
  is_active: false;
}

/**
 * Authenticate against qanteon instance
 *
 * @param baseUri   The base uri of Qanteon instance
 * @param username  The username provided
 * @param password  The password provided
 */
export const postSession = async (
  baseUri: string,
  username: string,
  password: string,
): Promise<Response<Session>> =>
  request<Session>('POST', url(baseUri, '/sessions'), {
    query: { username: window.btoa(username), password: window.btoa(password) },
  });

/**
 * Logout from qanteon instance
 *
 * @param baseUri   The base uri of Qanteon instance
 * @param token     Current token
 */
export const putSession = async (
  baseUri: string,
  token: string,
  session_id: number,
): Promise<Response<PutSession>> =>
  request<PutSession>('PUT', url(baseUri, '/sessions', `/${session_id}`), {
    token,
    payload: { is_active: false },
  });
