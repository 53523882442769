import React from 'react';
import { GreenSmileIcon } from './GreenSmileIcon';
import { YellowSmileIcon } from './YellowSmileIcon';
import { RedSmileIcon } from './RedSmileIcon';

interface SmileProps {
  className?: string;
  type?: 'red' | 'yellow' | 'green';
}

export const Smile: React.FC<SmileProps> = ({ className, type = 'green' }) => {
  switch (type) {
    case 'green':
      return <GreenSmileIcon className={className} />;
    case 'yellow':
      return <YellowSmileIcon className={className} />;
    case 'red':
      return <RedSmileIcon className={className} />;
  }
  return null;
};
