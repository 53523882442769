import React from 'react';
import { createClasses, classNames } from '../../../utils/css';
import logoLafinca from './logo-lafinca.svg';
import logoKP from './logo-kp.svg';
import { useAuth } from '../../../contexts/auth-provider';

const classes = createClasses({
  root: {
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'stretch',
  },
  logo: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    height: '100%',
    width: '100%',
  },
});

interface NameCardProps {
  className?: string;
}

export const NameCard: React.FC<NameCardProps> = ({ className }) => {
  const { baseUri } = useAuth();
  return (
    <div className={classNames(className, classes.root)}>
      <div
        className={classes.logo}
        data-testid="logo"
        style={{
          backgroundImage: `url(${
            baseUri.includes('lafinca') ? logoLafinca : logoKP
          })`,
        }}
      />
    </div>
  );
};
