import React from 'react';
import { Button } from '@kp/react-ui';
import { createClasses } from '../../utils/css';
import { DialogLayout } from '../../components/DialogLayout';
import { QanteonLogo } from '../../components/QanteonLogo/QanteonLogo';

const classes = createClasses({
  root: {
    padding: '48px 0',
    display: 'flex',
  },
  logo: {
    textAlign: 'center',
  },
  button: {
    marginTop: '48px',
    width: '100%',
  },
});

interface AuthenticateLayoutProps {
  handleClick: () => void;
  buttonText: string;
  error?: Error | null;
  children: React.ReactNode;
}

export const AuthenticateLayout: React.FC<AuthenticateLayoutProps> = ({
  handleClick,
  buttonText,
  error,
  children,
}) => {
  return (
    <DialogLayout className={classes.root} error={error}>
      <div className={classes.logo}>
        <QanteonLogo color="#1C1E21" />
      </div>
      {children}
      <Button className={classes.button} size="large" onClick={handleClick}>
        {buttonText}
      </Button>
    </DialogLayout>
  );
};
