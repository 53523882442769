import { Input } from '@kp/react-ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createClasses } from '../../utils/css';

const classes = createClasses({
  root: {
    paddingTop: '48px',
  },
  inputPassword: {
    marginTop: '24px',
  },
});

interface LoginProp {
  username: string;
  password: string;
  setUsername: (value: string) => void;
  setPassword: (value: string) => void;
}

export const Login: React.FC<LoginProp> = ({
  username,
  password,
  setUsername,
  setPassword,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Input
        onChange={(event) => setUsername(event.target.value)}
        fullWidth
        value={username}
        label={t('login.label.username')}
        size="large"
      />
      <Input
        onChange={(event) => setPassword(event.target.value)}
        fullWidth
        value={password}
        label={t('login.label.password')}
        size="large"
        type="password"
        className={classes.inputPassword}
      />
    </div>
  );
};
