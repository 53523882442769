import { useMemo, useCallback } from 'react';
import {
  format as formatDateFns,
  parse as parseDateFns,
  formatDuration as formatDurationFns,
} from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

const getLocale = (language: string) => {
  switch (language) {
    case 'en':
      return enUS;
    case 'es':
      return es;
    default:
      return enUS;
  }
};

export const useLocaleDateFns: () => {
  format: typeof formatDateFns;
  parse: typeof parseDateFns;
  formatDuration: typeof formatDurationFns;
} = () => {
  const { i18n } = useTranslation();

  const locale = useMemo(() => getLocale(i18n.language), [i18n.language]);

  const format: typeof formatDateFns = useCallback(
    (date, fmt, options) =>
      formatDateFns(date, fmt, {
        locale,
        ...options,
      }),
    [locale],
  );

  const parse: typeof parseDateFns = useCallback(
    (dateString, formatString, referenceDate, options) =>
      parseDateFns(dateString, formatString, referenceDate, {
        locale,
        ...options,
      }),
    [locale],
  );

  const formatDuration: typeof formatDurationFns = useCallback(
    (duration, options) =>
      formatDurationFns(duration, {
        locale,
        ...options,
      }),
    [locale],
  );

  return { format, parse, formatDuration };
};
