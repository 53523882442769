import React, {
  useMemo,
  useState,
  createContext,
  useContext,
  useCallback,
} from 'react';
import usePersistedStateSync from '@hoeks/use-persisted-state-sync';
import { useIntervalWhen } from 'rooks';
import { subMonths, startOfMonth, endOfMonth } from 'date-fns';
import {
  SelectBuilding,
  SelectBuildingLocation,
  SelectBuildingName,
  SelectStepTemplate,
} from '../features/SelectBuilding';
import { useAuth } from './auth-provider';
import { Summary } from '../features/SelectBuilding/Summary';
import { Dashboard, getDashboards, getValuation, ValuationItem } from '../api';
import { RequestError } from '../api/utils/RequestError';

type ScreenType =
  | 'summary'
  | 'buildingId'
  | 'buildingName'
  | 'buildingLocation'
  | 'info';

interface SettingsContextValue {
  buildingName: string;
  city: string;
  firstDashboard?: Dashboard;
  secondDashboard?: Dashboard;
  consumption: ValuationItem[];
  error?: RequestError | Error;
}

const SettingsContext = createContext<SettingsContextValue>({
  buildingName: '',
  city: '',
  firstDashboard: undefined,
  secondDashboard: undefined,
  consumption: [],
  error: undefined,
});

interface SettingsProviderProps {
  children?: React.ReactNode;
}

export const useSettings = (): SettingsContextValue =>
  useContext(SettingsContext);

export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children,
}) => {
  const { baseUri, signOut, withToken } = useAuth();

  const instance = baseUri.split('.')[0].split('//')[1];

  const [buildingId, setBuildingId] = usePersistedStateSync(
    '',
    `${instance}-buildingId`,
  );
  const [buildingName, setBuildingName] = usePersistedStateSync(
    '',
    `${instance}-buildingName`,
  );
  const [city, setCity] = usePersistedStateSync('', `${instance}-city`);
  const [error, setError] = useState<Error | RequestError>();

  const [current, setCurrent] = useState<ScreenType>(() =>
    buildingId ? 'summary' : 'buildingId',
  );

  const [firstDashboard, setFirstDashboard] = useState<Dashboard>();
  const [secondDashboard, setSecondDashboard] = useState<Dashboard>();
  const [consumption, setConsumption] = useState<ValuationItem[]>([]);

  const setValuation = useCallback(
    async (token: string, now: Date, utilityId: number) => {
      const valuationResponse = await getValuation(
        baseUri,
        token,
        buildingId,
        startOfMonth(subMonths(now, 23)),
        endOfMonth(now),
        utilityId,
      );
      if (valuationResponse?.data?.items) {
        setError(undefined);
        setConsumption(valuationResponse.data.items);
      } else throw new Error('No data found');
    },
    [baseUri, buildingId],
  );

  const update = useCallback(() => {
    withToken(async (token) => {
      const now = new Date();
      await getDashboards(baseUri, token, buildingId, now)
        .then((dashboardsResponse) => {
          if (dashboardsResponse.error) throw dashboardsResponse.error;
          if (
            dashboardsResponse?.data?.dashboards &&
            dashboardsResponse?.data?.dashboards.length > 1
          ) {
            setFirstDashboard(dashboardsResponse.data?.dashboards[0]);
            setSecondDashboard(dashboardsResponse.data?.dashboards[1]);
            setError(undefined);
            return dashboardsResponse.data?.dashboards[0].utility.id;
          }
          throw new Error('No data found');
        })
        .then(async (utilityId) => {
          await setValuation(token, now, utilityId);
          return null;
        });
    }).catch(setError);
  }, [withToken, baseUri, buildingId, setValuation]);

  useIntervalWhen(update, 1000 * 60 * 10, current === 'info', true);

  const value = useMemo(
    () => ({
      buildingName,
      city,
      firstDashboard,
      secondDashboard,
      consumption,
      error,
    }),
    [buildingName, city, firstDashboard, secondDashboard, consumption, error],
  );

  const next = () => {
    switch (current) {
      case 'summary': {
        setCurrent('info');
        break;
      }
      case 'buildingId': {
        if (buildingId) {
          setError(undefined);
          setBuildingId(buildingId);
          setCurrent('buildingName');
        } else setError(new Error('Enter BuildingID'));
        break;
      }
      case 'buildingName': {
        setBuildingName(buildingName);
        setCurrent('buildingLocation');
        break;
      }
      case 'buildingLocation': {
        setCity(city);
        setCurrent('info');
        break;
      }
    }
  };

  const previous = () => {
    switch (current) {
      case 'summary': {
        setCurrent('buildingId');
        break;
      }
      case 'buildingId': {
        signOut();
        break;
      }
      case 'buildingName': {
        setCurrent('buildingId');
        break;
      }
      case 'buildingLocation': {
        setCurrent('buildingName');
        break;
      }
    }
  };

  return (
    <SettingsContext.Provider value={value}>
      {current !== 'info' && (
        <SelectStepTemplate
          next={next}
          previous={previous}
          error={error}
          isSummary={current === 'summary'}
        >
          {current === 'summary' && (
            <Summary
              buildingId={buildingId}
              buildingName={buildingName}
              city={city}
            />
          )}
          {current === 'buildingId' && (
            <SelectBuilding
              buildingId={buildingId}
              setBuildingId={setBuildingId}
            />
          )}
          {current === 'buildingName' && (
            <SelectBuildingName
              buildingName={buildingName}
              setBuildingName={setBuildingName}
            />
          )}
          {current === 'buildingLocation' && (
            <SelectBuildingLocation city={city} setCity={setCity} />
          )}
        </SelectStepTemplate>
      )}
      {current === 'info' && children}
    </SettingsContext.Provider>
  );
};
