import React from 'react';
import { Colors, Typography } from '@kp/react-ui';
import { createClasses } from '../../utils/css';
import { ErrorIcon } from '../ErrorIcon';
import { DialogLayout } from '../DialogLayout';

const classes = createClasses({
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '40px',
  },
  title: {
    color: Colors.Neutral.text,
  },
});

interface ErrorLayoutProps {
  children?: React.ReactNode;
  className?: string;
  title: string;
}

export const ErrorLayout: React.FC<ErrorLayoutProps> = ({
  children,
  className,
  title,
}) => (
  <DialogLayout className={className}>
    <div className={classes.icon}>
      <ErrorIcon />
    </div>
    <Typography variant="headlineSmall" className={classes.title}>
      {title}
    </Typography>
    {children}
  </DialogLayout>
);
