import React from 'react';

interface UmbrellaIconProps {
  className?: string;
}

export const UmbrellaIcon: React.FC<UmbrellaIconProps> = ({ className }) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M27.9998 51.3333C27.5331 51.3333 27.096 51.2065 26.6884 50.953C26.2793 50.701 25.9776 50.3028 25.7831 49.7583L14.6414 16.1583C14.4859 15.7306 14.5738 15.351 14.9051 15.0197C15.2349 14.6899 15.6137 14.5833 16.0414 14.7L22.1664 16.1583L25.6664 13.475V7.93333C25.6664 6.37777 26.2303 5.05555 27.3581 3.96666C28.4859 2.87777 29.8664 2.33333 31.4998 2.33333C32.8998 2.33333 34.0758 2.70277 35.0278 3.44166C35.9813 4.18055 36.6137 5.11388 36.9248 6.24166C37.1581 7.09722 37.0609 7.82599 36.6331 8.42799C36.2053 9.03155 35.5442 9.33333 34.6498 9.33333C34.1053 9.33333 33.6581 9.20655 33.3081 8.95299C32.9581 8.70099 32.7248 8.32222 32.6081 7.81666C32.5303 7.50555 32.3942 7.26211 32.1998 7.08633C32.0053 6.91211 31.772 6.82499 31.4998 6.82499C31.1887 6.82499 30.9164 6.93155 30.6831 7.14466C30.4498 7.35933 30.3331 7.62222 30.3331 7.93333V13.475L33.8331 16.1583L39.9581 14.7C40.3859 14.5833 40.7654 14.6899 41.0968 15.0197C41.4266 15.351 41.5137 15.7306 41.3581 16.1583L30.2164 49.7C30.022 50.2444 29.7202 50.6528 29.3111 50.925C28.9036 51.1972 28.4664 51.3333 27.9998 51.3333ZM30.3331 34.5333L34.8831 20.65L32.7831 21.175L30.3331 19.3667V34.5333ZM25.6664 34.5333V19.3667L23.2164 21.2333L21.0581 20.65L25.6664 34.5333Z"
      fill="#5E646E"
    />
  </svg>
);
