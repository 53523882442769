import React from 'react';
import { Toast, Colors, LogoKP, Typography } from '@kp/react-ui';
import { createClasses, classNames } from '../../utils/css';
import { Card } from '../Card';
import { Layout } from '../Layout';

const classes = createClasses({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    color: Colors.Neutral.textStrong,
  },
  logo: {
    marginTop: '16px',
    width: '300px',
    height: '36px',
    marginBottom: '64px',
  },
  wrapper: {
    width: '392px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '-48px',
  },
  content: {
    marginTop: '24px',
    width: '100%',
  },
  notifications: {
    position: 'absolute',
    bottom: '190px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    right: 0,
  },
});

interface DialogLayoutProps {
  children?: React.ReactNode;
  className?: string;
  error?: Error | null;
}

export const DialogLayout: React.FC<DialogLayoutProps> = ({
  children,
  className,
  error,
}) => (
  <Layout className={classNames(classes.root, className)}>
    <div className={classes.wrapper}>
      <Card className={classes.content}>{children}</Card>
    </div>
    <footer className={classes.footer}>
      <Typography variant="bodyLarge">powered by</Typography>
      <LogoKP className={classes.logo} background="dark" />
    </footer>
    {error && (
      <div className={classes.notifications}>
        <Toast variant="danger">{error.message}</Toast>
      </div>
    )}
  </Layout>
);
