import React from 'react';

interface YellowSmileIconProps {
  className?: string;
}

export const YellowSmileIcon: React.FC<YellowSmileIconProps> = ({
  className,
}) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M36.1665 25.6667C37.1776 25.6667 38.0137 25.3361 38.6748 24.675C39.336 24.0139 39.6665 23.1778 39.6665 22.1667C39.6665 21.1555 39.336 20.3194 38.6748 19.6583C38.0137 18.9972 37.1776 18.6667 36.1665 18.6667C35.1554 18.6667 34.3193 18.9972 33.6582 19.6583C32.9971 20.3194 32.6665 21.1555 32.6665 22.1667C32.6665 23.1778 32.9971 24.0139 33.6582 24.675C34.3193 25.3361 35.1554 25.6667 36.1665 25.6667ZM19.8332 25.6667C20.8443 25.6667 21.6804 25.3361 22.3415 24.675C23.0026 24.0139 23.3332 23.1778 23.3332 22.1667C23.3332 21.1555 23.0026 20.3194 22.3415 19.6583C21.6804 18.9972 20.8443 18.6667 19.8332 18.6667C18.8221 18.6667 17.9859 18.9972 17.3248 19.6583C16.6637 20.3194 16.3332 21.1555 16.3332 22.1667C16.3332 23.1778 16.6637 24.0139 17.3248 24.675C17.9859 25.3361 18.8221 25.6667 19.8332 25.6667ZM22.7498 36.1667H33.2498C33.7554 36.1667 34.1738 36.001 34.5052 35.6697C34.835 35.3399 34.9998 34.9222 34.9998 34.4167C34.9998 33.9111 34.835 33.4927 34.5052 33.1613C34.1738 32.8315 33.7554 32.6667 33.2498 32.6667H22.7498C22.2443 32.6667 21.8266 32.8315 21.4968 33.1613C21.1655 33.4927 20.9998 33.9111 20.9998 34.4167C20.9998 34.9222 21.1655 35.3399 21.4968 35.6697C21.8266 36.001 22.2443 36.1667 22.7498 36.1667ZM27.9998 51.3333C24.7721 51.3333 21.7387 50.7204 18.8998 49.4947C16.061 48.2704 13.5915 46.6083 11.4915 44.5083C9.3915 42.4083 7.72939 39.9389 6.50517 37.1C5.27939 34.2611 4.6665 31.2278 4.6665 28C4.6665 24.7722 5.27939 21.7389 6.50517 18.9C7.72939 16.0611 9.3915 13.5917 11.4915 11.4917C13.5915 9.39166 16.061 7.72877 18.8998 6.50299C21.7387 5.27877 24.7721 4.66666 27.9998 4.66666C31.2276 4.66666 34.261 5.27877 37.0998 6.50299C39.9387 7.72877 42.4082 9.39166 44.5082 11.4917C46.6082 13.5917 48.2703 16.0611 49.4945 18.9C50.7203 21.7389 51.3332 24.7722 51.3332 28C51.3332 31.2278 50.7203 34.2611 49.4945 37.1C48.2703 39.9389 46.6082 42.4083 44.5082 44.5083C42.4082 46.6083 39.9387 48.2704 37.0998 49.4947C34.261 50.7204 31.2276 51.3333 27.9998 51.3333Z"
      fill="#D68000"
    />
  </svg>
);
